<template>
  <v-row>
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true">
      <template v-slot:main>
        <v-btn @click="dialogCreate=true" color="primary" :ripple="false"><v-icon left>mdi-file-plus</v-icon>Crear automatización</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="mt-0 px-0">
      <v-card flat>
        <v-toolbar color="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
            Documentos aceptados
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogEditAccept=true" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-pencil</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="">
          <div class="d-flex align-center">
            <div style="flex:1; min-width: 180px;">
              <v-card outlined>
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-playlist-check</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Reglas</v-list-item-title>
                    <v-list-item-subtitle>Si <span class="primary--text">todas</span> las reglas seleccionadas se cumplen</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
            <div class="ma-0" style="width: 360px;">
              <v-row align="center" justify="center" no-gutters>
                <img class="mr-2" :src="require(`@/assets/product-documents/automation/document-accept.svg`)" :min-width="300" :width="400" :height="200" :aspect-ratio="12/9"/>
              </v-row>
            </div>
            <div style="flex:1; min-width: 180px;">
              <v-card class="mt-0" outlined>
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-email</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Enviar un mail</v-list-item-title>
                    <v-list-item-subtitle>cesar.julca@farmaciasperuanas.pe</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-card class="my-5" outlined>
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-webhook</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Activar un webhook</v-list-item-title>
                    <v-list-item-subtitle>https://somosintercorp.net/recept</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-card outlined>
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-folder-network</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Publicar el documento en un SFTP</v-list-item-title>
                    <v-list-item-subtitle>192.158.1.38</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <!-- refused -->
      <v-card flat class="mt-5">
        <v-toolbar color="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
            Documentos aceptados
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogEditRefused=true" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-pencil</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text>
          <div class="d-flex align-center">
            <div style="flex:1; min-width: 180px;">
              <v-card outlined>
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-playlist-check</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Reglas</v-list-item-title>
                    <v-list-item-subtitle>Si <span class="primary--text">todas</span> las reglas seleccionadas se cumplen</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
            <div class="ma-0" style="width: 360px;">
              <v-row align="center" justify="center" no-gutters>
                <img class="mr-2" :src="require(`@/assets/product-documents/automation/document-refused.svg`)" :min-width="300" :width="400" :height="200" :aspect-ratio="12/9"/>
              </v-row>
            </div>
            <div style="flex:1; min-width: 180px;">
              <v-card class="mt-1" outlined>
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-email</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Enviar un mail</v-list-item-title>
                    <v-list-item-subtitle>paola.ventocilla@intercorpretail.pe</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <v-card outlined class="mt-5">
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-folder-network</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Publicar el documento en un SFTP</v-list-item-title>
                    <v-list-item-subtitle>192.158.1.38</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <!-- end refused -->
      <v-card flat class="mt-5">
        <v-toolbar color="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 secondary--text font-weight-semibold">
            Se responde de forma manual
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogEditEquall=true" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-pencil</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text>
          <div class="d-flex align-center">
            <div style="flex:1; min-width: 180px;">
              <v-card outlined>
                <v-list-item>
                  <v-list-item-avatar class="ml-2 mr-3 lightBlue" size="40">
                    <v-icon color="primary">mdi-playlist-check</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 secondary--text font-weight-semibold mb-1">Reglas</v-list-item-title>
                    <v-list-item-subtitle>Si <span class="primary--text">todas</span> las reglas seleccionadas se cumplen</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </div>
            <div class="ma-0" style="width: 360px;">
              <v-row align="center" justify="center" no-gutters>
                <img class="mr-2" :src="require(`@/assets/product-documents/automation/document-equall.svg`)" :min-width="300" :width="400" :height="200" :aspect-ratio="12/9"/>
              </v-row>
            </div>
            <div style="flex:1; min-width: 180px;">
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- dialog create -->
    <v-dialog v-model="dialogCreate" fullscreen persistent :scrollable="true" no-click-animation overlay-color="overlay">
      <v-card class="bgSearch" :img="require(`@/assets/product-documents/automation/background-modal.svg`)">
        <v-card-title class="pa-0 bgSearch">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Reglas de automatización</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreate=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 text-center fontBody--text">
          <v-col cols="9" class="mx-auto my-5">
            <v-card class="background d-block" flat>
              <v-card-text>
                <div class="d-flex align-center">
                  <div class="subtitle-2 fontBody--secondary font-weight-semibold">Nombre de la automatización</div>
                  <v-col class="pa-0 pl-5">
                    <v-text-field outlined single-line dense hide-details />
                  </v-col>
                </div>
              </v-card-text>
            </v-card>
            <v-divider vertical style="height: 20px; border-width:1px" />
            <div>
              <v-chip class="py-6 px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">
                <span class="mr-2">Si</span>
                <v-chip class="mr-2 pl-1 pr-0" color="bgSearch" text-color="primary">
                  <v-chip class="mr-2" :color="allOrSomeModel === 'all' ? 'primary' : 'white'" small @click="allOrSomeModel = 'all'">todas</v-chip>
                  <v-chip class="mr-2" :color="allOrSomeModel === 'some' ? 'primary' : 'barCompliance'" small @click="allOrSomeModel = 'some'">algunas</v-chip>
                </v-chip>
                {{ allOrSomeModel === 'all' ? 'las siguientes reglas se cumplen' : 'de las siguientes reglas se cumplen' }}
              </v-chip>
            </div>
            <v-divider vertical style="height: 20px; border-width:1px" />
            <v-card class="background d-block" flat>
              <v-card-text class="pt-8 pb-2">
                <v-row align="center" v-for="(r, i) in rules" :key="i">
                  <v-col class="pa-0 pl-5">
                    <v-autocomplete v-model="r.attributeModel" :items="attribute" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                  </v-col>
                    <v-col class="pa-0 pl-5">
                    <v-autocomplete v-if="r.attributeModel" v-model="r.comparisonOperatorsModel" :items="comparisonOperators[r.attributeModel]?.list || []" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                    <v-autocomplete v-else :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                  </v-col>
                  <v-col class="pa-0 pl-5">
                    <v-text-field v-if="comparisonOperators[r.attributeModel]?.input === 'number'" outlined single-line dense hide-details />
                    <v-autocomplete v-else-if="comparisonOperators[r.attributeModel]?.input === 'autocomplete'" :items="comparisonOperators[r.attributeModel]?.inputList ?? []" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                    <v-text-field v-else outlined single-line dense hide-details />
                  </v-col>
                  <v-col cols="1" style="max-width: 40px">
                    <v-btn text color="secondary" @click="rules.splice(i, 1)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                  </v-col>
                </v-row>
                <v-col class="text-left">
                  <v-btn class="font-weight-medium body-2" text color="primary" @click="addRow">+ Agregar otro</v-btn>
                </v-col>
              </v-card-text>
            </v-card>
            <v-divider vertical style="height: 20px; border-width:1px" />
            <div>
              <v-chip class="px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Entonces</v-chip>
            </div>
            <v-divider vertical style="height: 20px; border-width:1px" />
            <v-card :class="currentDocument.color" class="d-block" flat>
              <v-card-text class="py-0">
                <v-row no-gutters>
                  <v-col class="d-flex align-center">
                    <div class="subtitle-2 fontBody--secondary font-weight-semibold">El documento</div>
                    <v-col class="pa-0 pl-5">
                      <v-autocomplete v-model="documentModel" :items="documentOpcions" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" item-text="text" item-value="id" single-line outlined dense hide-details />
                    </v-col>
                  </v-col>
                  <v-col class="text-right">
                    <img class="mr-2" :src="require(`@/assets/product-documents/automation/${currentDocument.img}.svg`)" :height="100" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-divider vertical style="height: 20px; border-width:1px" />
            <div>
              <v-chip class="px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Y</v-chip>
            </div>
            <v-divider vertical style="height: 20px; border-width:1px" />
            <v-card class="background d-block" flat>
              <v-card-text class="py-6 text-left">
                <div class="subtitle-2 fontBody--secondary font-weight-semibold">Opcional</div>
                <div class="mt-1 subtitle-2 fontBody--secondary font-weight-semibold">Configuración personalizada del grupo</div>
                <v-switch v-model="activeMail" flat label="Envíar un email" hide-details color="success" :ripple="false"></v-switch>
                <div class="pt-5" v-if="activeMail">
                  Escribe la casilla (máximo 3 mails)
                  <v-col class="px-0">
                    <v-text-field outlined single-line dense hide-details />
                  </v-col>
                  <v-col class="pa-0">
                    <v-chip class="font-weight-light text-lowercase overline chip-users" close color="lightBlue" small label close-icon="mdi-close">
                      paola.ventocilla@intercorpretail.pe
                    </v-chip>
                    <v-chip class="ml-2 font-weight-light text-lowercase overline chip-users" close color="lightBlue" small label close-icon="mdi-close">
                      cesar.julca@farmaciasperuanas.pe
                    </v-chip>
                  </v-col>
                </div>
                <v-switch v-model="activeWebhook" flat label="Activar un webhook" hide-details color="success" :ripple="false"></v-switch>
                <div class="pt-5" v-if="activeWebhook">
                  Escribe la URL de conexión
                  <v-col class="px-0">
                    <v-text-field outlined single-line dense readonly hide-details />
                  </v-col>
                </div>
                <v-switch v-model="activeSftp" flat label="Publicar el documento en un SFTP" hide-details color="success" :ripple="false"></v-switch>
                <v-col class="pt-5 pa-0" v-if="activeSftp">
                  Escribe la URL
                  <v-col class="px-0">
                    <v-text-field outlined single-line dense readonly hide-details />
                  </v-col>
                  <v-col class="d-flex pa-0">
                    <div>
                      Subir certificado
                      <v-icon color="secondary" small>mdi-information</v-icon>
                    </div>
                    <div class="ml-4">
                      <v-col cols="12" class="py-0 mt-n3">
                        <v-file-input class="input-file-automation" type="file" accept=".xml" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                      </v-col>
                    </div>
                  </v-col>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5 bgSearch">
          <v-btn @click="dialogCreate=false" color="primary" text>Cancelar</v-btn>
          <v-spacer />
          <v-btn @click="dialogCreate=false" outlined>Guardar y agregar otra</v-btn>
          <v-btn @click="dialogCreate=false" color="primary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create -->
    <!-- dialog edit accept-->
    <v-dialog v-model="dialogEdit" fullscreen persistent :scrollable="true" no-click-animation overlay-color="overlay">
      <v-card class="bgSearch" :img="require(`@/assets/product-documents/automation/background-modal.svg`)">
        <v-card-title class="pa-0 bgSearch">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Reglas de automatización</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="close" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
          <v-card-text class="py-4 px-5 text-center fontBody--text">
            <v-col cols="9" class="mx-auto my-5">
              <v-card class="background d-block" flat>
                <v-card-text>
                  <div class="d-flex align-center">
                    <div class="subtitle-2 fontBody--secondary font-weight-semibold">Nombre de la automatización</div>
                    <v-col class="pa-0 pl-5">
                      <v-text-field v-model="currentRule.name" outlined single-line dense hide-details />
                    </v-col>
                  </div>
                </v-card-text>
              </v-card>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <div>
                <v-chip class="py-6 px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">
                  <span class="mr-2">Si</span>
                  <v-chip class="mr-2 pl-1 pr-0" color="bgSearch" text-color="primary">
                    <v-chip class="mr-2" :color="allOrSomeModel === 'all' ? 'primary' : 'white'" small @click="allOrSomeModel = 'all'">todas</v-chip>
                    <v-chip class="mr-2" :color="allOrSomeModel === 'some' ? 'primary' : 'barCompliance'" small @click="allOrSomeModel = 'some'">algunas</v-chip>
                  </v-chip>
                  {{ allOrSomeModel === 'all' ? 'las siguientes reglas se cumplen' : 'de las siguientes reglas se cumplen' }}
                </v-chip>
              </div>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <v-card class="background d-block" flat>
                <v-card-text class="pt-8 pb-2">
                  <v-row align="center" v-for="(r, i) in rules" :key="i">
                    <v-col class="pa-0 pl-5">
                      <v-autocomplete v-model="r.attributeModel" :items="attribute" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                    </v-col>
                      <v-col class="pa-0 pl-5">
                      <v-autocomplete v-if="r.attributeModel" v-model="r.comparisonOperatorsModel" :items="comparisonOperators[r.attributeModel]?.list || []" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                      <v-autocomplete v-else :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                    </v-col>
                    <v-col class="pa-0 pl-5">
                      <v-text-field v-if="comparisonOperators[r.attributeModel]?.input === 'number'" v-model="r.value" outlined single-line dense hide-details />
                      <v-autocomplete v-else-if="comparisonOperators[r.attributeModel]?.input === 'autocomplete'" v-model="r.value" :items="comparisonOperators[r.attributeModel]?.inputList ?? []" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details />
                      <v-text-field v-else outlined single-line dense hide-details />
                    </v-col>
                    <v-col cols="1" style="max-width: 40px">
                      <v-btn text color="secondary" @click="rules.splice(i, 1)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <v-col class="text-left">
                    <v-btn class="font-weight-medium body-2" text color="primary" @click="addRow">+ Agregar otro</v-btn>
                  </v-col>
                </v-card-text>
              </v-card>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <div>
                <v-chip class="px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Entonces</v-chip>
              </div>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <!-- accept, refused, equall -->
              <v-card v-if="dialogEdit" :class="currentRule.color" class="d-block" flat>
                <v-card-text class="py-0">
                  <v-row no-gutters>
                    <v-col class="d-flex align-center">
                      <div class="subtitle-2 fontBody--secondary font-weight-semibold">El documento</div>
                      <v-col class="pa-0 pl-5">
                        <v-autocomplete v-model="currentRule.type" :items="documentOpcions" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" item-text="text" item-value="id" single-line outlined dense hide-details />
                      </v-col>
                    </v-col>
                    <v-col class="text-right">
                      <img class="mr-2" :src="require(`@/assets/product-documents/automation/${currentRule.img}.svg`)" :height="100" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <!-- end accept, refused, equall -->
              <v-divider vertical style="height: 20px; border-width:1px" />
              <div>
                <v-chip class="px-5 font-weight-semibold rounded-pill" color="lightBlue" text-color="primary">Y</v-chip>
              </div>
              <v-divider vertical style="height: 20px; border-width:1px" />
              <v-card class="background d-block" flat>
                <v-card-text class="py-6 text-left">
                  <div class="subtitle-2 fontBody--secondary font-weight-semibold">Opcional</div>
                  <div class="mt-1 subtitle-2 fontBody--secondary font-weight-semibold">Configuración personalizada del grupo</div>
                  <v-switch v-model="activeMail" flat label="Envíar un email" hide-details color="success" :ripple="false"></v-switch>
                  <div class="pt-5" v-if="activeMail">
                    Escribe la casilla (máximo 3 mails)
                    <v-col class="px-0">
                      <v-text-field outlined single-line dense hide-details />
                    </v-col>
                    <v-col class="pa-0" v-if="currentRule.type !== 'equall'">
                      <v-chip class="font-weight-light text-lowercase overline chip-users" close color="lightBlue" small label close-icon="mdi-close">
                        paola.ventocilla@intercorpretail.pe
                      </v-chip>
                      <v-chip class="ml-2 font-weight-light text-lowercase overline chip-users" close color="lightBlue" small label close-icon="mdi-close">
                        cesar.julca@farmaciasperuanas.pe
                      </v-chip>
                    </v-col>
                  </div>
                  <v-switch v-model="activeWebhook" flat label="Activar un webhook" hide-details color="success" :ripple="false"></v-switch>
                  <div class="pt-5" v-if="activeWebhook">
                    Escribe la URL de conexión
                    <v-col class="px-0">
                      <v-text-field v-model="currentRule.webhook" outlined single-line dense hide-details />
                    </v-col>
                  </div>
                  <v-switch v-model="activeSftp" flat label="Publicar el documento en un SFTP" hide-details color="success" :ripple="false"></v-switch>
                  <v-col class="pt-5 pa-0" v-if="activeSftp">
                    Escribe la URL
                    <v-col class="px-0">
                      <v-text-field v-model="currentRule.sftp" outlined single-line dense  hide-details />
                    </v-col>
                    <v-col class="d-flex pa-0">
                      <div>
                        Subir certificado
                        <v-icon color="secondary" small>mdi-information</v-icon>
                      </div>
                      <div class="ml-4">
                        <v-col cols="12" class="py-0 mt-n3">
                          <v-file-input class="input-file-automation" type="file" accept=".xml" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                        </v-col>
                      </div>
                    </v-col>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
          </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5 bgSearch">
          <v-btn @click="close" color="error" text><v-icon size="20">mdi-delete</v-icon>Eliminar automatización</v-btn>
          <v-divider class="mx-5 my-1" vertical />
          <v-switch class="pa-0 ma-0" flat label="Desactivar automatización" color="success" hide-details></v-switch>
          <v-spacer />
          <v-btn @click="close" color="primary">Guardar cambios</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit accept -->
  </v-row>
</template>
<script>
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'

export default {
  components: {
    VEmptyState,
    MiniHeader
  },
  data: () => ({
    currentRule: {},
    allOrSomeModel: 'all',
    rules: [
      {
        comparisonOperatorsModel: null,
        attributeModel: null
      }
    ],
    activeMail: false,
    activeWebhook: false,
    activeSftp: false,
    dialogCreate: false,
    dialogEdit: false,
    dialogEditAccept: false,
    dialogEditRefused: false,
    dialogEditEquall: false,
    breadcrumbs: {
     main: 'Automatización',
     children: []
   },
   attribute: ['Fecha de emisión', 'Fecha de recepción', 'Referencia - Tipo de documento', 'Referencia - Número', 'Monto total', 'Monto neto', 'Giro del receptor', 'Fecha de recepción SII', ''],
   comparisonOperators: {
    'Fecha de emisión': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Fecha de recepción': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Referencia - Tipo de documento': {
      list: ['Es igual a'],
      input: 'autocomplete',
      inputList: ['OC', 'HES', 'Contrato', 'DUS', 'AWB', 'MIC/DTA', 'Ficha ChileCompra', 'Proceso ChileCompra', 'Resolución', 'Nota de pedido']
    },
    'Referencia - Número': {
      list: ['Es igual a', 'Es menor', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Monto total': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Monto neto': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    },
    'Giro del receptor': {
      list: ['Uno de'],
      input: 'autocomplete',
      inputList: ['VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN ALMACENES ESPECIALIZADOS', 'VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN ALMACENES ESPECIALIZADOS', 'OTRAS ACTIVIDADES DE VENTA AL POR MENOR NO REALIZADAS EN COMERCIOS, PUESTOS DE VENTA O MERCADOS']
    },
    'Fecha de recepción SII': {
      list: ['Es igual a', 'Es menor o igual a', 'Es menor a', 'Es mayor o igual a', 'Es mayor a', 'Entre'],
      input: 'number'
    }
   },
   documentModel: 'accept',
   documentOpcions: [
    {
      id: 'accept',
      text: 'Se acepta',
      img: 'create',
      color: 'backgroudLight'
    },
    {
      id: 'refused',
      text: 'Se rechaza',
      img: 'refused',
      color: 'redA'
    },
    {
      id: 'equall',
      text: 'Se mantiene igual',
      img: 'equall',
      color: 'yellowA'
    }
  ]
  }),
  computed: {
    currentColor () {
      if (this.dialogEditEquall) return 'yellowA'
      if (this.dialogEditRefused) return 'redA'
      return 'backgroudLight'
    },
    currentDocument () {
      return this.documentOpcions.find(({id}) => id === this.documentModel)
    }
  },
  watch: {
    dialogEditAccept (val) {
      if (val) {
        this.dialogEdit = true
        this.currentRule = {
          name: 'Documentos aceptados',
          mail: 'cesar.julca@farmaciasperuanas.pe',
          webhook: 'https://somosintercorp.net/recept',
          sftp: '192.158.1.38',
          type: 'accept',
          text: 'Se acepta',
          img: 'create',
          color: 'backgroudLight'
        }
        this.rules = [
          {
            attributeModel: 'Giro del receptor',
            comparisonOperatorsModel: 'Uno de',
            value: 'VENTA AL POR MENOR DE PRODUCTOS FARMACÉUTICOS Y MEDICINALES, COSMÉTICOS Y ARTÍCULOS DE TOCADOR EN ALMACENES ESPECIALIZADOS'
          },
          {
            attributeModel: 'Fecha de emisión',
            comparisonOperatorsModel: 'Es mayor o igual a',
            value: 8
          }
        ]
      }
    },
    dialogEditRefused (val) {
      if (val) {
        this.dialogEdit = true
        this.currentRule = {
          name: 'Documentos aceptados',
          mail: 'paola.ventocilla@intercorpretail.pe',
          webhook: 'https://somosintercorp.net/recept',
          sftp: '192.158.1.38',
          type: 'refused',
          img: 'refused',
          color: 'redA',
          text: 'Se rechaza'
        }
        this.rules = [
          {
            attributeModel: 'Referencia - Tipo de documento',
            comparisonOperatorsModel: 'Es igual a',
            value: 'OC'
          }
        ]
      }
    },
    dialogEditEquall (val) {
      if (val) {
        this.dialogEdit = true
        this.currentRule = {
          name: 'Se responde de forma manual',
          type: 'equall',
          img: 'equall',
          color: 'yellowA',
          text: 'Se mantiene igual'
        }
        this.rules = [
          {
            attributeModel: 'Fecha de emisión',
            comparisonOperatorsModel: 'Es mayor o igual a',
            value: '15'
          }
        ]
      }
    }
  },
  methods: {
    close () {
      this.dialogEditAccept = false
      this.dialogEditEquall = false
      this.dialogEditRefused = false
      this.dialogEdit = false
      this.currentRule = {}
      this.rules = [
        {
          comparisonOperatorsModel: null,
          attributeModel: null
        }
      ]
    },
    addRow () {
      this.rules.push({
        comparisonOperatorsModel: null,
        attributeModel: null
      })
    }
  }
}
</script>
<style>
  .input-file-automation.v-text-field, .vti__input {
    background: transparent
  }

  .input-file-automation.v-file-input .v-file-input__text {
    width: auto;
    margin-top: 10px;
  }

  .input-file-automation .v-icon__svg {
    height: 20px !important;
    width: 20px;
  }
</style>